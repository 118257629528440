<script setup lang="ts">
import { inject } from 'vue';
import InputCreditForm from './InputCreditForm.vue';
import {
  type CreditCardFormStore,
  CreditCardFormKey,
} from '@/store/CreditCardForm';
import { mulpayStoreKey, type MulpayStore } from '@/store/mulpay';

const cardForm = inject(CreditCardFormKey) as CreditCardFormStore;

const gmoShopAPIKey = cardForm.isDonation
  ? import.meta.env.VITE_GMO_SHOP_DONATION_API_KEY
  : import.meta.env.VITE_GMO_SHOP_API_KEY;
const gmoShopPubKey = cardForm.isDonation
  ? import.meta.env.VITE_GMO_SHOP_DONATION_PUB_KEY
  : import.meta.env.VITE_GMO_SHOP_PUB_KEY;

const mulpayStore = inject(mulpayStoreKey) as MulpayStore;
await mulpayStore.loadMulpay(gmoShopAPIKey, gmoShopPubKey);
</script>

<template>
  <Elements :multiPayment="mulpayStore.mulpay.value">
    <InputCreditForm />
  </Elements>
</template>
