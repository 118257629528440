<script lang="ts" setup>
import { computed } from 'vue';

import { MIcon, MText } from '@ca-crowdfunding/makuake-ui-n';

type Value = string | number | boolean | null;

const props = withDefaults(
  defineProps<{
    disabled?: boolean;
    modelValue?: Value;
    name: string;
    options: {
      type?: string;
      label?: string;
      value?: Value;
    }[];
  }>(),
  {
    disabled: false,
  },
);

const emit = defineEmits<{
  'update:modelValue': [value?: Value];
}>();

const internalValue = computed({
  get: () => props.modelValue,
  set: (v) => emit('update:modelValue', v),
});
</script>

<template>
  <div class="MRadio" v-bind="$attrs">
    <div
      v-for="(option, index) in options"
      :key="index"
      class="mb-2 flex items-center flexbox"
    >
      <div class="relative mr-1 flex shrink-0 items-center justify-center">
        <MIcon
          :color="internalValue === option.value ? 'positive' : 'primary'"
          :name="
            internalValue === option.value
              ? 'radioButtonChecked'
              : 'radioButtonUnchecked'
          "
          size="medium"
        />
        <input
          :id="`${name}-${index}`"
          v-model="internalValue"
          class="absolute h-8 w-8 cursor-pointer opacity-0"
          :disabled="disabled"
          :name="name"
          type="radio"
          :value="option.value"
        />
      </div>
      <label class="inline-flex cursor-pointer label" :for="`${name}-${index}`">
        <MText
          tag="span"
          :weight="internalValue === option.value ? 'bold' : 'normal'"
          >{{ option.label }}</MText
        >
      </label>
      <slot :name="['slot', option.type].join('-')"></slot>
    </div>
  </div>
</template>

<style scoped>
.flexbox {
  flex-wrap: wrap;
}

.label {
  width: 80%;
}
</style>
