<script setup lang="ts">
import { ref, onMounted, inject } from 'vue';
import type { AxiosError } from 'axios';
import router from '@/router';
import PaymentLayout from '@/components/common/PaymentLayout.vue';
import SelectRadioButtons from './SelectRadioButtons.vue';
import { MPanel, MSpinner, MText } from '@ca-crowdfunding/makuake-ui-n';
import Buttons from '@/components/common/Buttons.vue';
import {
  TranService,
  type TranCheckRequest,
  type TranAvailablePaymentTypeResponse,
  PaymentType,
  ErrorCode,
  type Error,
  type TranDestinationResponse,
} from '@/generated/payment-api';
import { createMakuakeWebOrderURL } from '@/utils';
import { type errorStore, errorStoreKey } from '@/store/error';
import { isTimeoutError } from '@/generated/payment-api/core/request';

const store = inject(errorStoreKey) as errorStore;
const errorMessages = ref<string[]>([]);
const isLoadingContents = ref<boolean>(true);
const isLoadingButton = ref<boolean>(false);
const targetPage = ref<string>('');
const paymentTypes = ref<PaymentType[]>([]);
const projectType = ref<string>('');

onMounted(() => {
  TranService.tranAvailablePaymentType()
    .then((response: TranAvailablePaymentTypeResponse) => {
      paymentTypes.value = response.payment_types;
      projectType.value = response.project_type;
      isLoadingContents.value = false;
    })
    .catch((error: AxiosError<Error>) => {
      if (!error.response) {
        isTimeoutError(error, store, false) ? router.push(`/error`) : null;
        return;
      }
      const { status } = error.response;
      const { code, message } = error.response.data;
      if (status === 401 && code === ErrorCode.AUTHENTICATION_ERROR) {
        window.location.assign(import.meta.env.VITE_MAKUAKE_URL + '/login/');
        return;
      }
      store.code = code;
      store.messages = [message];
      router.push(`/error`);
    });
});

const handleNegative = () => {
  isLoadingButton.value = true;
  // TODO セッションからOrderIDを削除するAPIを叩く
  TranService.tranDestination()
    .then((response: TranDestinationResponse) => {
      const { project_code, project_return_id, order_id } = response;
      const url = createMakuakeWebOrderURL(
        project_code,
        project_return_id,
        order_id,
      );
      window.location.assign(url);
    })
    .catch((error: AxiosError<Error, any>) => {
      if (!error.response) {
        isTimeoutError(error, store, false) ? router.push(`/error`) : null;
        return;
      }
      const { status } = error.response;
      const { code, message } = error.response.data;
      if (status === 401 && code === ErrorCode.AUTHENTICATION_ERROR) {
        window.location.assign(import.meta.env.VITE_MAKUAKE_URL + '/login/');
        return;
      }
      store.code = code;
      store.messages = [message];
      router.push(`/error`);
    });
};

const handlePositive = () => {
  if (targetPage.value === '') {
    errorMessages.value = ['『支払方法』が未設定です。'];
    window.scrollTo(0, 0);
  }
  if (targetPage.value !== '/confirm') {
    router.push(targetPage.value);
    return;
  }
  isLoadingButton.value = true;

  const body: TranCheckRequest = {
    payment_type: PaymentType.GANB,
  };
  TranService.tranCheck(body)
    .then(() => {
      router.push(`/confirm`);
    })
    .catch((error: AxiosError<Error, any>) => {
      isLoadingButton.value = false;
      if (!error.response) {
        isTimeoutError(error, store, false) ? router.push(`/error`) : null;
        return;
      }
      const { status } = error.response;
      const { code, message, details } = error.response.data;
      if (status === 400 && code === ErrorCode.INVALID_ARGUMENT && details) {
        errorMessages.value = Object.values(details);
        window.scrollTo(0, 0);
        return;
      }
      if (status === 401 && code === ErrorCode.AUTHENTICATION_ERROR) {
        window.location.assign(import.meta.env.VITE_MAKUAKE_URL + '/login/');
        return;
      }
      store.code = code;
      store.messages = [message];
      router.push(`/error`);
    });
};
</script>

<template>
  <PaymentLayout
    title="支払方法をお選び下さい"
    :step="2"
    :errorMessages="errorMessages"
  >
    <template v-slot:contents>
      <template v-if="isLoadingContents">
        <MSpinner size="medium" />
      </template>
      <template v-else>
        <SelectRadioButtons
          v-model="targetPage"
          :paymentTypes="paymentTypes"
          :project-type="projectType"
        />
        <MPanel color="disabled">
          <MText lg="small">
            ※現金決済のお支払い期限は、お申込み当日を含めた7日以内です。ただしプロジェクト期間の終了まで残り7日をきる場合は、お支払い期限が一律でプロジェクト終了日中となります。お申込み完了後に「お支払い期限」を必ずご確認ください。
          </MText>
        </MPanel>
      </template>
    </template>
    <template v-slot:buttons>
      <MSpinner v-if="isLoadingButton" size="medium" />
      <Buttons
        v-if="!isLoadingButton"
        :handleNegative="handleNegative"
        :handlePositive="handlePositive"
      />
    </template>
  </PaymentLayout>
</template>
