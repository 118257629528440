<script setup lang="ts">
import { inject } from 'vue';
import {
  MPanel,
  MText,
  MTextField,
  MCheckbox,
} from '@ca-crowdfunding/makuake-ui-n';
import Divider from '@/components/common/Divider.vue';
import InputCreditLabel from './InputCreditLabel.vue';
import {
  type CreditCardFormStore,
  CreditCardFormKey,
} from '@/store/CreditCardForm';

const myPageCreditListURL =
  import.meta.env.VITE_MAKUAKE_URL + '/my/settings/credit/';

const cardForm = inject(CreditCardFormKey) as CreditCardFormStore;
</script>

<template>
  <div class="card-number-input-label">
    <InputCreditLabel text="クレジットカード番号" />
    <img
      v-if="cardForm.isDonation"
      src="@/assets/img/card_brand_logo_gcf.png"
    />
    <img v-else src="@/assets/img/card_brand_logo.png" />
  </div>
  <div class="card-number-input">
    <CardNumberElement class="mp-token-element" />
  </div>
  <Divider />
  <InputCreditLabel text="有効期限" />
  <div class="expiration-date">
    <CardExpiryElement class="mp-token-element" />
  </div>
  <Divider />
  <InputCreditLabel text="セキュリティコード" />
  <div class="security-code">
    <CardCvcElement class="mp-token-element" />
  </div>
  <Divider />
  <InputCreditLabel text="カード名義人" />
  <div class="text-field">
    <MTextField
      v-model="cardForm.holderName"
      id="card-holder"
      name="card-holder"
      placeholder="TARO MAKUAKE"
      type="text"
      maxlength="50"
    />
  </div>
  <Divider />
  <template v-if="cardForm.canRegisterCard()">
    <MCheckbox
      v-model="cardForm.shouldRegisterCard"
      id="registers-card"
      label="利用したカード情報を登録する"
      name="registers-card"
    />
  </template>
  <template v-else>
    <MPanel color="accent" outlined>
      <MText color="black" size="small">
        既に登録されているカード枚数が上限5枚に達しています。新規で登録される場合は、マイページの
        <MText color="info" size="small" :href="myPageCreditListURL">
          クレジットカード管理
        </MText>
        より削除してください。
      </MText>
    </MPanel>
  </template>
  <Divider />
</template>

<style scoped>
.card-number-input-label {
  display: flex;
  justify-content: left;
  gap: 16px;
}

.card-number-input-label img {
  max-width: 150px;
  max-height: 20px;
}

.card-number-input {
  width: 100%;
  max-width: 220px;
}

.mp-token-element {
  border-width: 1px;
  border-style: solid;
  border-color: rgb(197 197 197);
  border-radius: 8px;
  padding: 10px 16px 0;
  color: black;
}

.expiration-date {
  width: 100%;
  max-width: 130px;
}

.security-code {
  width: 100%;
  max-width: 130px;
}
</style>
