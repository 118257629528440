<script setup lang="ts">
import { inject, ref } from 'vue';
import type { AxiosError } from 'axios';
import PaymentLayout from '@/components/common/PaymentLayout.vue';
import { MText } from '@ca-crowdfunding/makuake-ui-n';
import { MTextField } from '@ca-crowdfunding/makuake-ui-n';
import Buttons from '@/components/common/Buttons.vue';
import router from '@/router';
import {
  TranService,
  type TranCheckRequest,
  PaymentType,
  ErrorCode,
  type Error,
} from '@/generated/payment-api';
import { type errorStore, errorStoreKey } from '@/store/error';
import { isTimeoutError } from '@/generated/payment-api/core/request';

const payer = ref<{ name: string; nameKana: string; phoneNumber: string }>({
  name: '',
  nameKana: '',
  phoneNumber: '',
});
const store = inject(errorStoreKey) as errorStore;
const isLoadingButton = ref<boolean>(false);
const errorMessage = ref<string[]>([]);

const handleInputName = (event: Event) => {
  const target = event.target as HTMLSelectElement;
  payer.value.name = target.value;
};

const handleInputNameKana = (event: Event) => {
  const target = event.target as HTMLSelectElement;
  payer.value.nameKana = target.value;
};

const handleInputPhoneNumber = (event: Event) => {
  const target = event.target as HTMLSelectElement;
  payer.value.phoneNumber = target.value;
};

const handleNegative = () => {
  isLoadingButton.value = true;
  router.push('/select');
};

const handlePositive = () => {
  isLoadingButton.value = true;
  payer.value.name = payer.value.name.trim();
  payer.value.nameKana = payer.value.nameKana.trim();
  payer.value.phoneNumber = payer.value.phoneNumber.trim();
  const body: TranCheckRequest = {
    payment_type: PaymentType.PAYEASY,
    payer: {
      name: payer.value.name,
      name_kana: payer.value.nameKana,
      phone_number: payer.value.phoneNumber,
    },
  };
  TranService.tranCheck(body)
    .then(() => {
      router.push(`/confirm`);
    })
    .catch((error: AxiosError<Error, any>) => {
      isLoadingButton.value = false;
      if (!error.response) {
        isTimeoutError(error, store, false) ? router.push(`/error`) : null;
        return;
      }
      const { status } = error.response;
      const { code, message, details } = error.response.data;

      if (status === 400 && code === ErrorCode.INVALID_ARGUMENT && details) {
        errorMessage.value = Object.values(details);
        window.scrollTo(0, 0);
        return;
      }
      if (status === 401 && code === ErrorCode.AUTHENTICATION_ERROR) {
        window.location.assign(import.meta.env.VITE_MAKUAKE_URL + '/login/');
        return;
      }
      store.code = code;
      store.messages = [message];
      router.push(`/error`);
    });
};
</script>

<template>
  <PaymentLayout
    :step="2"
    title="ペイジー決済の必要事項を入力してください"
    :errorMessages="errorMessage"
  >
    <template v-slot:contents>
      <MText
        align="left"
        color="primary"
        size="medium"
        tag="div"
        weight="bold"
        class="title"
      >
        お名前
      </MText>
      <div class="item-field">
        <MTextField
          id="name"
          name="name"
          type="text"
          placeholder="幕開太郎"
          maxlength="15"
          @input="handleInputName"
          v-model="payer.name"
        />
      </div>

      <MText
        align="left"
        color="primary"
        size="medium"
        tag="div"
        weight="bold"
        class="title"
      >
        フリガナ（全角）
      </MText>
      <div class="item-field">
        <MTextField
          id="name-kana"
          name="name-kana"
          type="text"
          placeholder="マクアケタロウ"
          maxlength="15"
          @input="handleInputNameKana"
          v-model="payer.nameKana"
        />
      </div>

      <MText
        align="left"
        color="primary"
        size="medium"
        tag="div"
        weight="bold"
        class="title"
      >
        電話番号（ハイフンなし）
      </MText>
      <div class="item-field">
        <MTextField
          id="phone-number"
          name="phone-number"
          type="text"
          placeholder="08012345678"
          maxlength="11"
          @input="handleInputPhoneNumber"
          v-model="payer.phoneNumber"
        />
      </div>
    </template>
    <template v-slot:buttons>
      <MSpinner v-if="isLoadingButton" size="medium" />
      <Buttons
        v-if="!isLoadingButton"
        :handleNegative="handleNegative"
        :handlePositive="handlePositive"
      />
    </template>
  </PaymentLayout>
</template>

<style scoped>
.title {
  margin: 0 0 14px 0;
}
.item-field {
  line-height: 1.8;
  margin: 5px 0 25px;
  padding: 8px;
}
</style>
