import './assets/css/main.css';
import '@ca-crowdfunding/makuake-ui-n/dist/makuake-ui-n.css';
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import setupSentry from './sentry';
import { useErrorStore, errorStoreKey } from '@/store/error';
import { useMulpay, mulpayStoreKey } from '@/store/mulpay';
import VueMultiPayment from '@mul-pay/mptoken-vue-js';

const app = createApp(App);
setupSentry(app);
app.provide(errorStoreKey, useErrorStore());
app.provide(mulpayStoreKey, useMulpay());
app.use(router);
app.use(VueMultiPayment);

app.mount('#app');
