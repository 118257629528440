<script setup lang="ts">
import { MButton } from '@ca-crowdfunding/makuake-ui-n';

const props = withDefaults(
  defineProps<{
    handleNegative: () => void;
    handlePositive: () => void;
    textPositive?: string;
  }>(),
  { textPositive: '次へ' },
);
</script>

<template>
  <div id="button-area" class="wrap-button-with-back">
    <MButton
      @click="props.handleNegative"
      variant="tonal"
      size="x-large"
      rounded="none"
      class="button button-back"
    >
      戻る
    </MButton>
    <MButton
      @click="props.handlePositive"
      color="accent"
      size="x-large"
      rounded="none"
      class="button button-yellow"
    >
      {{ textPositive }}
    </MButton>
  </div>
</template>

<style scoped>
.wrap-button-with-back {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: auto;
  max-width: 720px;
  padding-bottom: 35px;
  width: 100%;
}

.button {
  border: 0;
  border-radius: 2px;
  color: #ffffff;
  cursor: pointer;
  flex: 1;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.5;
  padding: 20px;
  text-align: center;
  width: 335px;
}

.wrap-button-with-back .button {
  flex: inherit;
}

.wrap-button-with-back .button-yellow {
  margin: unset;
}

.button-yellow {
  background: #f7c600;
}

.wrap-button .button:not(:last-of-type) {
  margin-bottom: 35px;
}

.button-yellow:hover {
  background: #f9d133;
  text-decoration: none;
}

.button-back {
  background: #c8c8c8;
}

.button-back:hover {
  background: #d3d3d3;
  text-decoration: none;
}

.button-back {
  margin-right: 24px;
  width: 160px;
}

@media only screen and (width <= 667px) {
  .wrap-button-with-back {
    justify-content: space-between;
    padding: 0 20px;
    padding-bottom: env(safe-area-inset-bottom);
  }

  .wrap-button-with-back .button-yellow {
    flex: 2;
  }

  .wrap-button-with-back .button-back {
    flex: 1;
    margin-right: 20px;
  }

  .wrap-button .button:not(:last-of-type) {
    margin-bottom: 20px;
  }

  .button {
    width: 100%;
  }
}
</style>
