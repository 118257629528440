<script setup lang="ts">
import { MText } from '@ca-crowdfunding/makuake-ui-n';

const props = defineProps<{
  text: string;
}>();
</script>

<template>
  <MText
    align="left"
    color="primary"
    size="medium"
    tag="p"
    weight="bold"
    class="label"
  >
    {{ props.text }}
  </MText>
</template>

<style scoped>
.label {
  margin-bottom: 12px;
}
</style>
