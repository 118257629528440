<script setup lang="ts">
import { provide } from 'vue';
import {
  CreditCardFormKey,
  useCreditCardFormStore,
} from '@/store/CreditCardForm';
import InputCreditElement from './InputCreditElement.vue';
import { MSpinner } from '@ca-crowdfunding/makuake-ui-n';

provide(CreditCardFormKey, useCreditCardFormStore());
</script>

<template>
  <Suspense>
    <InputCreditElement />
    <template #fallback>
      <MSpinner size="medium" />
    </template>
  </Suspense>
</template>
