<script setup lang="ts">
import { inject } from 'vue';
import { MSelect } from '@ca-crowdfunding/makuake-ui-n';
import InputCreditLabel from './InputCreditLabel.vue';
import {
  type CreditCardFormStore,
  CreditCardFormKey,
} from '@/store/CreditCardForm';

const cardForm = inject(CreditCardFormKey) as CreditCardFormStore;

const handleCardNumberSelect = (event: Event) => {
  const target = event.target as HTMLSelectElement;
  cardForm.selectedCardId = target.value;
};
</script>

<template>
  <InputCreditLabel text="ご利用になるクレジットカードを選択してください。" />
  <MSelect
    v-model="cardForm.selectedCardId"
    name="card-number-select"
    :options="cardForm.registeredCardList"
    class="card-number-select"
    @change="handleCardNumberSelect"
  />
</template>

<style scoped>
.m-select {
  width: 250px;
}
</style>
