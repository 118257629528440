import { createRouter, createWebHistory } from 'vue-router';
import SelectPage from '@/components/pages/select/SelectPage.vue';
import InputCvsPage from '@/components/pages/input/cvs/InputCvsPage.vue';
import InputPayeasyPage from '@/components/pages/input/payeasy/InputPayeasyPage.vue';
import ConfirmPage from '@/components/pages/confirm/ConfirmPage.vue';
import ErrorPage from '@/components/pages/error/ErrorPage.vue';
import InputCreditPage from '@/components/pages/input/credit/InputCreditPage.vue';

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/select',
      name: 'select',
      component: SelectPage,
      meta: { title: '支払い方法選択 | Makuake（マクアケ）' },
    },
    {
      path: '/input/credit',
      name: 'input-credit',
      component: InputCreditPage,
      meta: { title: 'クレジットカード情報入力 | Makuake（マクアケ）' },
    },
    {
      path: '/input/cvs',
      name: 'input-cvs',
      component: InputCvsPage,
      meta: { title: 'コンビニ決済情報入力 | Makuake（マクアケ）' },
    },
    {
      path: '/input/payeasy',
      name: 'input-payeasy',
      component: InputPayeasyPage,
      meta: { title: 'ペイジー情報入力 | Makuake（マクアケ）' },
    },
    {
      path: '/confirm',
      name: 'confirm',
      component: ConfirmPage,
      meta: { title: '注文確認 | Makuake（マクアケ）' },
    },
    {
      path: '/error',
      name: 'error',
      component: ErrorPage,
      meta: { title: 'エラーが発生しました | Makuake（マクアケ）' },
    },
  ],
  scrollBehavior() {
    return { top: 0 };
  },
});

router.afterEach((to) => {
  if (typeof to.meta.title === 'string') {
    document.title = to.meta.title;
  }
});

export default router;
