/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type TranCard = {
    /**
     * クレジットカード番号（マスク済み）
     */
    cardNumber: string;
    /**
     * クレジットカード有効期限（月）
     */
    expiryMonth: string;
    /**
     * クレジットカード有効期限（年）
     */
    expiryYear: string;
    /**
     * カードブランド
     */
    brand: TranCard.brand;
    /**
     * GMOに登録されているカードのID
     */
    cardId: string;
    /**
     * デフォルトカード
     */
    isDefault: boolean;
};

export namespace TranCard {

    /**
     * カードブランド
     */
    export enum brand {
        VISA = 'VISA',
        MASTERCARD = 'MASTERCARD',
        JCB = 'JCB',
        DINERS = 'DINERS',
        AMEX = 'AMEX',
        UNKNOWN = 'UNKNOWN',
    }


}

