<script setup lang="ts">
const props = defineProps<{
  step: number;
}>();
</script>

<template>
  <div class="wrap-steps">
    <nav class="nav">
      <div class="nav-background">
        <div v-if="props.step == 1" class="nav-foreground step1" />
        <div v-if="props.step == 2" class="nav-foreground step2" />
        <div v-if="props.step == 3" class="nav-foreground step3" />
        <div v-if="props.step == 4" class="nav-foreground step4" />
      </div>
    </nav>
  </div>
</template>

<style scoped>
.wrap-steps {
  display: flex;
  justify-content: center;
  width: 100%;
}

.nav {
  flex: 1;
  margin: 0 20px;
  padding-bottom: 50px;
  text-align: center;
}

.nav-background {
  background: #e6e6e6;
  border-radius: 100px;
  height: 10px;
  margin: auto;
  max-width: 500px;
}

.nav-foreground {
  background: #f7c600;
  border-radius: 100px;
  height: 100%;
  position: relative;
}

.nav-foreground:after {
  background: #f7c800;
  border-radius: 20px;
  content: '';
  height: 20px;
  position: absolute;
  right: -11px;
  top: -5px;
  width: 20px;
}

@media only screen and (max-width: 667px) {
  .nav {
    padding-bottom: 36px;
  }

  .nav-background {
    max-width: 260px;
  }
}

.step1 {
  width: 25%;
}

.step2 {
  width: 50%;
}

.step3 {
  width: 75%;
}

.step4 {
  width: 100%;
}
</style>
