import {
  type OpenAPIConfig,
  CancelablePromise,
  ErrorCode,
} from '@/generated/payment-api';
import type { ApiRequestOptions } from '@/generated/payment-api/core/ApiRequestOptions';
import axios from 'axios';
import type { AxiosError, AxiosResponse, AxiosRequestConfig } from 'axios';
import type { errorStore } from '@/store/error';

const TIMEOUT = 30_000;
export const TIMEOUT_ERROR = 'ECONNABORTED';
export const TIMEOUT_ERROR_BY_EXEC = 'ECONNABORTED_BY_EXEC';
export const isTimeoutError = (
  error: AxiosError<any>,
  store: errorStore,
  isExec: boolean = false,
): boolean => {
  if (error.code === TIMEOUT_ERROR) {
    if (isExec) {
      store.code = TIMEOUT_ERROR_BY_EXEC;
      store.messages = [
        '応援購入の結果が取得できませんでした。応援購入履歴から正しく購入できたかを確認してください。',
        'もし購入が完了していない場合は、再度購入をお試しください。',
      ];
    } else {
      store.code = TIMEOUT_ERROR;
      store.messages = [
        '通信がタイムアウトしました。通信環境をご確認の上、再度お試しください。',
      ];
    }
    return true;
  }
  return false;
};

interface UserStatusResponse {
  is_logined: boolean;
}

const createClient = (options: AxiosRequestConfig) => {
  const baseOptions = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const config = { ...baseOptions, ...options };
  return axios.create(config);
};

const makuakeWebClient = createClient({
  headers: {
    'Accept-Language': 'ja',
  },
  baseURL: import.meta.env.VITE_MAKUAKE_URL + '/api',
  withCredentials: true,
  timeout: TIMEOUT,
});

const paymentApiClient = createClient({
  baseURL: import.meta.env.VITE_PAYMENT_API_BASE_URL,
  withCredentials: true,
  timeout: TIMEOUT,
});

paymentApiClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (!error || !error.response || !error.response.data) {
      return Promise.reject(error);
    }
    const { status } = error.response;
    const { code } = error.response.data;
    if (status === 401 && code === ErrorCode.TOKEN_EXPIRED) {
      return makuakeWebClient
        .get(`/userstatus/userstatus`)
        .then((response: AxiosResponse<UserStatusResponse>) => {
          if (!response.data.is_logined) throw Error('not logged in');
          return paymentApiClient.request(error.config);
        })
        .catch(() => {
          window.location.assign(import.meta.env.VITE_MAKUAKE_URL + '/login/');
        });
    }
    return Promise.reject(error);
  },
);

const source = axios.CancelToken.source();

export const request = <T>(
  config: OpenAPIConfig,
  options: ApiRequestOptions,
): CancelablePromise<T> => {
  return new CancelablePromise((resolve, reject) => {
    paymentApiClient
      .request({
        url: options.url,
        data: options.body,
        method: options.method,
        cancelToken: source.token,
      })
      .then((response: AxiosResponse) => {
        resolve(response.data);
      })
      .catch((error: AxiosError) => {
        reject(error);
      });
  });
};
