import { PaymentType, CvsCode } from '@/generated/payment-api';

const paymentTypeMap: Map<PaymentType, string> = new Map([
  [PaymentType.CARD, 'クレジットカード'],
  [PaymentType.CVS, 'コンビニ決済'],
  [PaymentType.PAYEASY, 'ペイジー決済（Pay-easy）'],
  [PaymentType.GANB, '銀行振込（GMOあおぞらネット銀行 バーチャル口座）'],
]);

const cvsCodeMap: Map<CvsCode, string> = new Map([
  [CvsCode.LAWSON, 'ローソン'],
  [CvsCode.FAMILYMART, 'ファミリーマート'],
  [CvsCode.MINISTOP, 'ミニストップ'],
  [CvsCode.SEICOMART, 'セイコーマート'],
]);

export { paymentTypeMap, cvsCodeMap };
