/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * プロジェクトタイプ
 */
export enum ProjectType {
    CROWDFUNDING = 'CROWDFUNDING',
    DONATION = 'DONATION',
}
