function createMakuakeWebOrderURL(
  projectCode: string,
  project_return_id: number,
  order_id?: string,
) {
  let url = `${import.meta.env.VITE_MAKUAKE_URL}/ja/payment/cf/${projectCode}/${project_return_id}`;
  if (order_id) {
    url += '?order_id=' + order_id;
  }
  return url;
}

export { createMakuakeWebOrderURL };
