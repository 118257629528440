<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 200 38.382"
  >
    <title>Makuake</title>
    <path
      d="M52.906,32.464V5.94c0-0.686,0.457-1.28,1.143-1.418c0.686-0.138,1.326,0.183,1.646,0.779
          c0.778,1.692,1.555,3.338,2.333,4.937c1.281,2.745,2.744,5.946,3.659,7.82c0.869-1.875,2.333-5.122,3.659-7.82
          c0.777-1.6,1.555-3.245,2.333-4.937c0.32-0.595,0.96-0.916,1.646-0.779c0.686,0.137,1.143,0.732,1.143,1.418v26.524
          c0,0.869-0.686,1.463-1.463,1.463c-0.869,0-1.463-0.594-1.463-1.463V12.48c-1.098,2.333-2.195,4.709-3.064,6.539
          c-0.503,1.052-1.005,2.104-1.463,3.111c-0.32,0.548-0.732,0.823-1.326,0.823c-0.64,0-1.098-0.275-1.326-0.823
          c-0.458-1.007-0.961-2.059-1.463-3.111c-0.869-1.829-2.012-4.206-3.064-6.539v19.984c0,0.869-0.686,1.463-1.463,1.463
          C53.5,33.928,52.906,33.333,52.906,32.464z"
    ></path>
    <path
      d="M88.672,18.653c0-1.279-0.778-2.377-1.921-2.88c-1.235-0.503-2.516-0.228-3.384,0.685c-0.274,0.275-0.595,0.412-1.006,0.412
          c-0.366,0-0.686-0.138-0.96-0.412c-0.275-0.274-0.412-0.594-0.412-0.96c0-0.411,0.137-0.732,0.412-0.96
          c1.692-1.692,4.207-2.195,6.402-1.28c2.195,0.914,3.612,3.018,3.612,5.395v13.63c0,0.776-0.64,1.37-1.372,1.37h-4.573
          c-3.293,0-5.945-2.651-5.945-5.944c0-3.293,2.652-5.945,5.945-5.945h3.201V18.653z M85.471,30.91h3.201v-6.404h-3.201
          c-1.784,0-3.201,1.418-3.201,3.202C82.27,29.492,83.687,30.91,85.471,30.91z"
    ></path>
    <path
      d="M114.102,33.653c-0.686,0.366-1.509,0.185-1.875-0.502l-5.259-9.65l-2.195,2.423v6.54c0,0.778-0.64,1.373-1.372,1.373
          c-0.778,0-1.372-0.595-1.372-1.373V5.025c0-0.777,0.594-1.372,1.372-1.372c0.732,0,1.372,0.595,1.372,1.372v16.784l7.637-8.551
          c0.503-0.549,1.371-0.595,1.966-0.093c0.549,0.503,0.595,1.373,0.092,1.921l-5.579,6.219l5.762,10.518
          C115.017,32.51,114.788,33.287,114.102,33.653z"
    ></path>
    <path
      d="M123.618,27.708V14.172c0-0.777,0.594-1.373,1.372-1.373c0.778,0,1.372,0.595,1.372,1.373v13.536
          c0,1.784,1.418,3.202,3.201,3.202h3.201V14.172c0-0.777,0.594-1.373,1.372-1.373c0.778,0,1.372,0.595,1.372,1.373v18.111
          c0,0.776-0.594,1.37-1.372,1.37h-4.573C126.27,33.653,123.618,31.001,123.618,27.708z"
    ></path>
    <path
      d="M153.804,18.653c0-1.279-0.778-2.377-1.921-2.88c-1.235-0.503-2.516-0.228-3.384,0.685
          c-0.274,0.275-0.595,0.412-1.006,0.412c-0.366,0-0.686-0.138-0.96-0.412c-0.275-0.274-0.412-0.594-0.412-0.96
          c0-0.411,0.137-0.732,0.412-0.96c1.692-1.692,4.207-2.195,6.402-1.28c2.195,0.914,3.612,3.018,3.612,5.395v13.63
          c0,0.776-0.64,1.37-1.372,1.37h-4.573c-3.293,0-5.945-2.651-5.945-5.944c0-3.293,2.652-5.945,5.945-5.945h3.201V18.653z
           M150.603,30.91h3.201v-6.404h-3.201c-1.784,0-3.201,1.418-3.201,3.202C147.402,29.492,148.819,30.91,150.603,30.91z"
    ></path>
    <path
      d="M179.234,33.653c-0.686,0.366-1.509,0.185-1.875-0.502l-5.259-9.65l-2.195,2.423v6.54c0,0.778-0.64,1.373-1.372,1.373
          c-0.777,0-1.372-0.595-1.372-1.373V5.025c0-0.777,0.595-1.372,1.372-1.372c0.732,0,1.372,0.595,1.372,1.372v16.784l7.637-8.551
          c0.503-0.549,1.373-0.595,1.967-0.093c0.549,0.503,0.594,1.373,0.091,1.921l-5.579,6.219l5.762,10.518
          C180.149,32.51,179.92,33.287,179.234,33.653z"
    ></path>
    <path
      d="M188.11,27.891v-9.146c0-3.293,2.652-5.946,5.945-5.946c3.293,0,5.945,2.653,5.945,5.946v4.573
          c0,0.777-0.594,1.373-1.372,1.373h-7.774v3.2c0,1.28,0.732,2.469,1.967,2.972c1.234,0.503,2.561,0.228,3.476-0.685
          c0.274-0.275,0.594-0.412,0.96-0.412c0.411,0,0.732,0.137,1.006,0.412c0.274,0.228,0.412,0.548,0.412,0.96
          c0,0.366-0.137,0.685-0.412,0.96c-1.692,1.692-4.253,2.195-6.494,1.281C189.528,32.464,188.11,30.314,188.11,27.891z
           M190.854,18.745v3.201h6.402v-3.201c0-1.784-1.418-3.201-3.201-3.201C192.272,15.544,190.854,16.961,190.854,18.745z"
    ></path>
    <path
      fill="#BBF117"
      d="M36.256,15.822C35.394,11.45,32.885,7.674,29.41,5.14v9.736c0.605,0.48,2.206,1.573,4.264,1.573
          C34.558,16.449,35.419,16.222,36.256,15.822z"
    ></path>
    <path
      fill="#FB2A79"
      d="M17.868,22.834V1.86c-3.057,0.231-5.898,1.243-8.313,2.856v18.122c0.61,0.484,2.197,1.558,4.234,1.558
          C15.211,24.396,16.583,23.871,17.868,22.834z"
    ></path>
    <path
      fill="#FFE600"
      d="M27.827,18.951V4.108c-2.463-1.416-5.297-2.246-8.323-2.3v17.184c0.634,0.495,2.202,1.536,4.214,1.536
          C25.152,20.528,26.534,19.998,27.827,18.951z"
    ></path>
    <path
      fill="#FFFFFF"
      d="M33.731,18.023c-1.795,0-3.311-0.671-4.326-1.274v2.57c0,0.228-0.099,0.445-0.27,0.594
          c-1.666,1.453-3.469,2.189-5.357,2.189c-1.768,0-3.259-0.645-4.273-1.239v2.353c0,0.238-0.102,0.462-0.28,0.617
          c-1.672,1.458-3.481,2.196-5.376,2.196c-1.816,0-3.343-0.679-4.36-1.286v2.816c0,0.216-0.089,0.423-0.244,0.571
          c-0.017,0.016-1.434,1.3-3.731,1.779c3.188,4.059,8.127,6.681,13.677,6.681c9.594,0,17.399-7.805,17.399-17.399
          c0-0.581-0.031-1.155-0.087-1.722C35.605,17.837,34.679,18.023,33.731,18.023z"
    ></path>
    <path
      fill="#1AD4FF"
      d="M7.912,5.968C4.173,9.162,1.793,13.9,1.793,19.191c0,3.419,1.005,6.602,2.716,9.294
          c1.718-0.163,2.924-0.934,3.403-1.299V5.968z"
    ></path>
    <path
      d="M37.782,14.506C35.688,6.176,28.171,0,19.191,0C8.592,0,0,8.592,0,19.191c0,3.308,0.837,6.42,2.311,9.137
          c0.324,0.599,0.686,1.174,1.07,1.733c3.461,5.024,9.248,8.322,15.81,8.322c10.599,0,19.191-8.592,19.191-19.191
          c0-0.903-0.084-1.784-0.204-2.653C38.082,15.848,37.949,15.171,37.782,14.506z M29.41,5.14c3.475,2.534,5.985,6.31,6.846,10.682
          c-0.837,0.4-1.698,0.627-2.582,0.627c-2.059,0-3.66-1.093-4.264-1.573V5.14z M19.505,1.808c3.025,0.054,5.86,0.884,8.323,2.3v14.843
          c-1.293,1.046-2.675,1.576-4.108,1.576c-2.012,0-3.58-1.04-4.214-1.536V1.808z M9.556,4.715c2.415-1.613,5.255-2.624,8.313-2.856
          v20.975c-1.285,1.036-2.657,1.561-4.079,1.561c-2.037,0-3.624-1.075-4.234-1.558V4.715z M1.793,19.191
          c0-5.291,2.381-10.029,6.119-13.222v21.218c-0.479,0.365-1.685,1.135-3.403,1.299C2.797,25.793,1.793,22.61,1.793,19.191z
           M19.191,36.59c-5.55,0-10.49-2.622-13.677-6.681c2.297-0.479,3.714-1.763,3.731-1.779c0.155-0.148,0.244-0.355,0.244-0.571v-2.816
          c1.017,0.608,2.543,1.286,4.36,1.286c1.896,0,3.705-0.739,5.376-2.196c0.178-0.155,0.28-0.379,0.28-0.617v-2.353
          c1.013,0.594,2.505,1.239,4.273,1.239c1.889,0,3.691-0.736,5.357-2.189c0.171-0.149,0.27-0.366,0.27-0.594v-2.57
          c1.015,0.604,2.531,1.274,4.326,1.274c0.948,0,1.874-0.186,2.771-0.554c0.056,0.567,0.087,1.141,0.087,1.722
          C36.59,28.785,28.785,36.59,19.191,36.59z"
    ></path>
  </svg>
</template>
