<script setup lang="ts">
import PaymentErrorLayout from './PaymentErrorLayout.vue';
import {
  ErrorCode,
  type TranDestinationResponse,
  TranService,
} from '@/generated/payment-api';

import { inject, onMounted, ref } from 'vue';
import { MButton } from '@ca-crowdfunding/makuake-ui-n';
import { type errorStore, errorStoreKey } from '@/store/error';
import { createMakuakeWebOrderURL } from '@/utils';
import router from '@/router';
import {
  TIMEOUT_ERROR,
  TIMEOUT_ERROR_BY_EXEC,
} from '@/generated/payment-api/core/request';

const store = inject(errorStoreKey) as errorStore;
const errorMessages = ref<string[]>([]);
const errorCode = ref<string>('');
const destination = ref({
  projectCode: '',
  projectReturnID: 0,
  orderID: '',
});

onMounted(() => {
  errorMessages.value = store.messages;
  errorCode.value = store.code;
  TranService.tranDestination().then((response: TranDestinationResponse) => {
    destination.value = {
      projectCode: response.project_code,
      projectReturnID: response.project_return_id,
      orderID: response.order_id,
    };
  });
});

const handleProjectButton = () => {
  const url = [
    import.meta.env.VITE_MAKUAKE_URL,
    'project',
    destination.value.projectCode,
  ];
  window.location.assign(url.join('/'));
};

const handleFavoriteButton = () => {
  const url = [import.meta.env.VITE_MAKUAKE_URL, 'my/project/favorite'];
  window.location.assign(url.join('/'));
};

const handleOrderButton = () => {
  const url = createMakuakeWebOrderURL(
    destination.value.projectCode,
    destination.value.projectReturnID,
    destination.value.orderID,
  );
  window.location.assign(url);
};

const handleReenterButton = () => {
  router.push('/input/credit');
};

const handleTopButton = () => {
  window.location.assign(import.meta.env.VITE_MAKUAKE_URL);
};

const handleSelectAnotherPaymentTypeButton = () => {
  router.push('/select');
};
</script>
<template>
  <PaymentErrorLayout :errorMessages="errorMessages">
    <template v-slot:contents>
      <p>エラーが発生しました</p>
    </template>
    <template v-slot:buttons>
      <div class="flex-wrap">
        <MButton
          v-if="
            errorCode === ErrorCode.SOLD_OUT ||
            errorCode === TIMEOUT_ERROR ||
            errorCode === ErrorCode.PAYMENT_SERVICE_SERVER_ERROR
          "
          @click="handleProjectButton"
          size="x-large"
          rounded="none"
          class="button button-yellow flex-content"
        >
          プロジェクトページへ戻る
        </MButton>
        <MButton
          v-else-if="errorCode === TIMEOUT_ERROR_BY_EXEC"
          @click="handleFavoriteButton"
          size="x-large"
          rounded="none"
          class="button button-yellow"
        >
          応援購入履歴画面に戻る
        </MButton>
        <MButton
          v-else-if="errorCode === ErrorCode.PAYMENT_TIMEOUT"
          @click="handleOrderButton"
          size="x-large"
          rounded="none"
          class="button button-yellow"
        >
          注文画面に戻る
        </MButton>
        <MButton
          v-else-if="errorCode === ErrorCode.PAYMENT_SERVICE_AUTH_ERROR"
          @click="handleReenterButton"
          size="x-large"
          rounded="none"
          class="button button-yellow"
        >
          再入力する
        </MButton>
        <MButton
          v-else
          @click="handleTopButton"
          size="x-large"
          rounded="none"
          class="button button-yellow"
        >
          トップページへ戻る
        </MButton>
        <MButton
          v-if="errorCode === ErrorCode.PAYMENT_SERVICE_AUTH_ERROR"
          @click="handleSelectAnotherPaymentTypeButton"
          color="info"
          size="x-large"
          rounded="none"
          class="button"
        >
          異なる決済方法を選ぶ
        </MButton>
      </div>
    </template>
  </PaymentErrorLayout>
</template>

<style scoped>
.button {
  border: 0;
  border-radius: 2px;
  color: #ffffff;
  cursor: pointer;
  flex: 1;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.5;
  padding: 20px;
  text-align: center;
  width: 335px;
  margin-bottom: 20px;
}

.button-yellow {
  background: #f7c600;
}

.flex-wrap {
  display: flex;
  flex-flow: column;
  align-items: center;
}
</style>
