/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TranAvailablePaymentTypeResponse } from '../models/TranAvailablePaymentTypeResponse';
import type { TranCardsResponse } from '../models/TranCardsResponse';
import type { TranCheckRequest } from '../models/TranCheckRequest';
import type { TranDestinationResponse } from '../models/TranDestinationResponse';
import type { TranExecRequest } from '../models/TranExecRequest';
import type { TranExecResponse } from '../models/TranExecResponse';
import type { TranStartRequest } from '../models/TranStartRequest';
import type { TranTemporaryResponse } from '../models/TranTemporaryResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TranService {

    /**
     * 決済開始API
     * 注文画面から決済画面へのリダイレクトを行う
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public static tranStart(
        requestBody?: TranStartRequest,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/tran/start',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `バリデーションエラー`,
                401: `認証エラー`,
            },
        });
    }

    /**
     * 決済情報チェックAPI
     * 入力した決済情報をチェックしてSessionに保持するAPI
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public static tranCheck(
        requestBody?: TranCheckRequest,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/tran/check',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `バリデーションエラー`,
                401: `認証エラー`,
            },
        });
    }

    /**
     * 仮注文情報取得API
     * Sessionに保持されたOrderIDから注文情報を取得するAPI
     * @returns TranTemporaryResponse 成功時のレスポンス
     * @throws ApiError
     */
    public static tranTemporary(): CancelablePromise<TranTemporaryResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/tran/temporary',
            errors: {
                400: `バリデーションエラー`,
                401: `認証エラー`,
            },
        });
    }

    /**
     * 決済実行API
     * 決済を実行するAPI
     * @param requestBody
     * @returns TranExecResponse 成功時のレスポンス
     * @throws ApiError
     */
    public static tranExec(
        requestBody?: TranExecRequest,
    ): CancelablePromise<TranExecResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/tran/exec',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `バリデーションエラー`,
                401: `認証エラー`,
            },
        });
    }

    /**
     * 利用可能な決済手段取得API
     * セッションに保持されたOrderIDから利用可能な決済手段とそのプロジェクトタイプを取得するAPI
     * @returns TranAvailablePaymentTypeResponse 成功時のレスポンス
     * @throws ApiError
     */
    public static tranAvailablePaymentType(): CancelablePromise<TranAvailablePaymentTypeResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/tran/available-payment-type',
            errors: {
                400: `バリデーションエラー`,
                401: `認証エラー`,
            },
        });
    }

    /**
     * 登録済みクレジットカード取得API
     * GMOに登録されたクレジットカードを取得するAPI
     * @returns TranCardsResponse 成功時のレスポンス
     * @throws ApiError
     */
    public static tranCards(): CancelablePromise<TranCardsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/tran/cards',
            errors: {
                400: `バリデーションエラー`,
                401: `認証エラー`,
            },
        });
    }

    /**
     * 遷移先情報取得API
     * Sessionに保持されたOrderIDからプロジェクト情報を取得するAPI
     * @returns TranDestinationResponse 成功時のレスポンス
     * @throws ApiError
     */
    public static tranDestination(): CancelablePromise<TranDestinationResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/tran/destination',
            errors: {
                401: `認証エラー`,
            },
        });
    }

}
