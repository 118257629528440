import { reactive } from 'vue';

const errorStoreKey = Symbol('errorStoreKey');

interface errorStore {
  code: string;
  messages: string[];
  clear(): void;
}

const useErrorStore = () => {
  const state = reactive({
    code: '',
    messages: [],
  });
  return {
    get code() {
      return state.code;
    },
    set code(code) {
      state.code = code;
    },
    get messages() {
      return state.messages;
    },
    set messages(messages) {
      state.messages = messages;
    },
    clear: () => {
      state.code = '';
      state.messages = [];
    },
  };
};

export { type errorStore, useErrorStore, errorStoreKey };
