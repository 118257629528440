<script setup lang="ts">
const companyUrl = import.meta.env.VITE_MAKUAKE_URL + '/pages/company/';
const termUrl = import.meta.env.VITE_MAKUAKE_URL + '/pages/term/';
const privacyPolicyUrl = import.meta.env.VITE_MAKUAKE_URL + '/pages/privacy/';
</script>

<template>
  <footer class="footer">
    <div>
      <ul class="footer-bottom-link">
        <li>
          <a class="footer-bottom-link-text" target="_blank" :href="companyUrl"
            >運営会社</a
          >
        </li>
        <li>
          <a class="footer-bottom-link-text" target="_blank" :href="termUrl"
            >利用規約</a
          >
        </li>
        <li>
          <a
            class="footer-bottom-link-text"
            target="_blank"
            :href="privacyPolicyUrl"
            >プライバシーポリシー<br />(情報の外部送信に関する内容を含む)</a
          >
        </li>
      </ul>
      <div class="footer-bottom-copy">
        <small class="footer-bottom-copy-text"
          >Copyright © Makuake, Inc. All Rights Reserved.</small
        >
      </div>
    </div>
  </footer>
</template>

<style scoped>
.footer {
  background: #fff;
  padding: 40px;
  text-align: center;
  margin-top: auto;
}

/* 運営会社 利用規約 プライバシーポリシー  コピーライト */
.footer-bottom-link {
  display: inline-flex;
}

.footer-bottom-link-text {
  color: #777;
  font-size: 14px;
  padding: 0 14px;
}

.footer-bottom-copy {
  padding-top: 20px;
}

.footer-bottom-copy-text {
  color: #717171;
  font-size: 13px;
  letter-spacing: 0.02em;
}

@media only screen and (width <=667px) {
  .footer-bottom-link-text {
    font-size: 11px;
    padding: 0 8px;
  }

  .footer-bottom-copy-text {
    font-size: 9px;
  }
}
</style>
