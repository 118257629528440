<script setup lang="ts">
import { ref, onMounted, inject } from 'vue';
import PaymentLayout from '@/components/common/PaymentLayout.vue';
import { MText, MSpinner, MPanel } from '@ca-crowdfunding/makuake-ui-n';
import router from '@/router';
import Buttons from '@/components/common/Buttons.vue';
import {
  TranService,
  ErrorCode,
  type TranExecRequest,
  type TranTemporaryResponse,
  type TranExecResponse,
  type Error,
} from '@/generated/payment-api';
import { paymentTypeMap, cvsCodeMap } from '@/consts';
import type { AxiosError } from 'axios';
import { type errorStore, errorStoreKey } from '@/store/error';
import { isTimeoutError } from '@/generated/payment-api/core/request';

const store = inject(errorStoreKey) as errorStore;
const isLoadingContents = ref<boolean>(true);
const isLoadingButton = ref<boolean>(false);
const projectTitle = ref('');
const projectReutrnTitle = ref('');
const deliveryDate = ref('');
const paymentPrice = ref(0);
const paymentType = ref('');
const selectedCVS = ref('');
const csrfToken = ref('');

const textPositive = ref('確定する');

onMounted(() => {
  TranService.tranTemporary()
    .then((response: TranTemporaryResponse) => {
      projectTitle.value = response.project_title;
      projectReutrnTitle.value = response.project_return_title;
      deliveryDate.value = response.delivery_date;
      paymentPrice.value = response.payment_price;
      const pt = paymentTypeMap.get(response.payment_type);
      paymentType.value = pt ? pt : '';
      if (response.cvs_code) {
        const cc = cvsCodeMap.get(response.cvs_code);
        selectedCVS.value = cc ? cc : '';
      }
      csrfToken.value = response.csrf_token;
      isLoadingContents.value = false;
    })
    .catch((error: AxiosError<Error, any>) => {
      if (!error.response) {
        isTimeoutError(error, store, false) ? router.push(`/error`) : null;
        return;
      }
      const { status } = error.response;
      const { code, message } = error.response.data;

      if (status === 401 && code === ErrorCode.AUTHENTICATION_ERROR) {
        window.location.assign(import.meta.env.VITE_MAKUAKE_URL + '/login/');
        return;
      }
      store.code = code;
      store.messages = [message];
      router.push(`/error`);
    });
});

const handleNegative = () => {
  isLoadingButton.value = true;
  router.go(-1);
};

const handlePositive = () => {
  isLoadingButton.value = true;
  const body: TranExecRequest = {
    csrf_token: csrfToken.value,
  };
  TranService.tranExec(body)
    .then((response: TranExecResponse) => {
      window.location.assign(response.redirect_url);
    })
    .catch((error: AxiosError<Error, any>) => {
      isLoadingButton.value = false;
      if (!error.response) {
        isTimeoutError(error, store, true) ? router.push(`/error`) : null;
        return;
      }
      const { status } = error.response;
      const { code, message } = error.response.data;

      if (status === 401 && code === ErrorCode.AUTHENTICATION_ERROR) {
        window.location.assign(import.meta.env.VITE_MAKUAKE_URL + '/login/');
        return;
      }
      store.code = code;
      store.messages = [message];
      router.push(`/error`);
    });
};
</script>

<template>
  <PaymentLayout
    :step="3"
    :title="[
      '下記の内容で申し込みます。',
      'よろしければ、「確定する」ボタンを押してください。',
    ]"
  >
    <template v-slot:contents>
      <template v-if="isLoadingContents">
        <MSpinner size="medium" />
      </template>
      <template v-else>
        <MText
          align="left"
          color="primary"
          size="medium"
          tag="div"
          weight="bold"
          class="title"
        >
          プロジェクト名
        </MText>
        <div class="confirm-item">
          <p>{{ projectTitle }}</p>
        </div>

        <MText
          align="left"
          color="primary"
          size="medium"
          tag="div"
          weight="bold"
          class="title"
        >
          リターン名（商品名）
        </MText>
        <div class="confirm-item">
          <p>{{ projectReutrnTitle }}</p>
        </div>

        <MText
          align="left"
          color="primary"
          size="medium"
          tag="div"
          weight="bold"
          class="title"
        >
          お届け予定
        </MText>
        <div class="confirm-item">
          <p>{{ deliveryDate }}</p>
        </div>

        <MText
          align="left"
          color="primary"
          size="medium"
          tag="div"
          weight="bold"
          class="title"
        >
          支払方法
        </MText>
        <div class="confirm-item">
          <p>{{ paymentType }}</p>
        </div>

        <div v-if="selectedCVS.length !== 0">
          <MText
            align="left"
            color="primary"
            size="medium"
            tag="div"
            weight="bold"
            class="title"
          >
            選択したコンビニ
          </MText>
          <div class="confirm-item">
            <p>{{ selectedCVS }}</p>
          </div>
        </div>

        <MText
          align="left"
          color="primary"
          size="medium"
          tag="div"
          weight="bold"
          class="title"
        >
          お支払総額
        </MText>
        <div class="confirm-item">
          <p>{{ paymentPrice.toLocaleString() + '円' }}</p>
        </div>

        <MPanel color="white" class="caution">
          <MText lg="small">
            ※在庫数に限りがある場合、申込みが殺到すると、申込みが完了したとしても「在庫切れ」となり入金できない、またはキャンセルとなることがあります。申込み完了後に届くメールを必ずご確認ください。
          </MText>
          <MText lg="small">
            ※原則として、決済完了後にご自身の都合によるキャンセルを受け付けておりません。
          </MText>
          <MText lg="small">
            ※プロジェクトやリターンの内容、製品に関するお問い合わせは、プロジェクト実行者へ直接お問い合わせ願います。
          </MText>
          <MText lg="small">
            ※お届けまで時間がかかる場合がありますので、「お届け予定」欄を必ずご確認願います。
          </MText>
        </MPanel>
      </template>
    </template>
    <template v-slot:buttons>
      <MSpinner v-if="isLoadingButton" size="medium" />
      <Buttons
        v-if="!isLoadingButton"
        :handleNegative="handleNegative"
        :handlePositive="handlePositive"
        :textPositive="textPositive"
      />
    </template>
  </PaymentLayout>
</template>

<style scoped>
.title {
  margin: 0 0 14px 0;
}

.message {
  margin: 0 0 30px 0;
}

.confirm-item {
  background-color: #f5f5f5;
  line-height: 1.8;
  margin: 5px 0 25px;
  padding: 8px;
}

.caution {
  background-color: #f4f4f4;
  margin-top: 40px;
  padding: 14px 20px;
}
</style>
