import { ref, type Ref } from 'vue';
import { loadMulpay, type MultiPayment } from '@mul-pay/mptoken-js';

const mulpayStoreKey = Symbol('mulpayStoreKey');

interface MulpayStore {
  mulpay: Ref<MultiPayment | null>;
  loadMulpay: (gmoShopAPIKey: string, gmoShopPubKey: string) => void;
}

function useMulpay(): MulpayStore {
  const state = ref<MultiPayment | null>(null);
  return {
    mulpay: state,
    loadMulpay: async (gmoShopAPIKey: string, gmoShopPubKey: string) => {
      if (!state.value) {
        state.value = await loadMulpay(
          gmoShopAPIKey,
          gmoShopPubKey,
          null,
          true,
          import.meta.env.MODE === 'prd',
        );
      }
    },
  };
}

export { type MulpayStore, useMulpay, mulpayStoreKey };
