import { reactive } from 'vue';

const CreditCardFormKey = Symbol('CreditCardFormKey');

interface CreditCardFormStore {
  holderName: string;
  isDonation: boolean;
  useNewCard: boolean;
  shouldRegisterCard: boolean;
  registeredCardList: { label: string; value: string }[];
  selectedCardId: string;
  clear: () => void;
  canRegisterCard: () => boolean;
  hasRegisteredCard: () => boolean;
  registeredCardCount: () => number;
  isInputedHolderName: () => boolean;
  getSelectedCardId: () => string;
}

const useCreditCardFormStore = () => {
  const maxRegisteredCardNumber = 5;
  const state = reactive({
    holderName: '',
    isDonation: false,
    useNewCard: false,
    shouldRegisterCard: false,
    registeredCardList: [],
    selectedCardId: '',
  });
  return {
    get holderName() {
      return state.holderName;
    },
    set holderName(holdername) {
      state.holderName = holdername;
    },
    get isDonation() {
      return state.isDonation;
    },
    set isDonation(isDonation) {
      state.isDonation = isDonation;
    },
    get useNewCard() {
      return state.useNewCard;
    },
    set useNewCard(useNewCard) {
      state.useNewCard = useNewCard;
    },
    get shouldRegisterCard() {
      return state.shouldRegisterCard;
    },
    set shouldRegisterCard(shouldRegisterCard) {
      state.shouldRegisterCard = shouldRegisterCard;
    },
    get registeredCardList() {
      return state.registeredCardList;
    },
    set registeredCardList(registeredCardList) {
      state.registeredCardList = registeredCardList;
      state.useNewCard = state.registeredCardList.length === 0;
    },
    get selectedCardId() {
      return state.selectedCardId;
    },
    set selectedCardId(selectedCardId) {
      state.selectedCardId = selectedCardId;
    },
    clear: () => {
      state.holderName = '';
      state.isDonation = false;
      state.useNewCard = false;
      state.shouldRegisterCard = false;
      state.selectedCardId = '';
    },
    canRegisterCard: () => {
      return state.registeredCardList.length < maxRegisteredCardNumber;
    },
    hasRegisteredCard: () => {
      return state.registeredCardList.length >= 1;
    },
    registeredCardCount: () => {
      return state.registeredCardList.length;
    },
    getSelectedCardId: () => {
      if (state.selectedCardId.length === 0) {
        return undefined;
      }
      return state.selectedCardId;
    },
    isInputedHolderName: () => {
      return state.holderName.trim().length !== 0;
    },
  };
};

export { type CreditCardFormStore, useCreditCardFormStore, CreditCardFormKey };
