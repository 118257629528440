/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * コンビニコード
 */
export enum CvsCode {
    LAWSON = 'LAWSON',
    FAMILYMART = 'FAMILYMART',
    MINISTOP = 'MINISTOP',
    SEICOMART = 'SEICOMART',
}
